import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'reactstrap';
import moment from 'moment';

import useBlockchain from '../../hooks/useBlockchain';

const HistoryTable = () => {
  const { contractReader, contractWriter, active, walletAddress } = useBlockchain();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fn = async () => {
      if(!active) {
        return
      }

      try {
        const allocationCount = parseInt(await contractReader.allocationLen(walletAddress));
        console.log(allocationCount)
        const h = [];
        for (let i = 0; i < allocationCount; i++) {
          const allocation = await contractReader.allocationInfo(walletAddress, i);
          h.push({index: i+1, ...allocation})
        }

        setHistory(h);
      } catch (error) {
        console.log(error)
      }
      
    }

    fn()
  }, [active])

  const timeLeft = (timestampSec) => {
    const nextClaimTime = moment(
      timestampSec
    );

    const duration = moment.duration(nextClaimTime.diff(moment()));

    const days = String(Math.floor(duration.asDays())).padStart(2, '0');
    if (days >= 1) return `${days} days`

    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    if(hours >= 1) return `${hours} hours`

    const minutes = String(duration.minutes()).padStart(2, '0');
    if(minutes >= 1)
      return `${minutes} minutes`

    const seconds = String(duration.seconds()).padStart(2, '0');
    if(seconds >= 1)
      return `${seconds} seconds`

    return 'now'
  }

  const claimAllocation = async (index) => {
    try {
      const tx = await contractWriter.claimAllocation(index)
      await tx.wait();
      alert('Transaction submitted')
      location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="history-table">
      <Row>
        <h3>Allocations</h3>
        <Table striped responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Total Amount</th>
              <th>Claimed Amount</th>
              <th>Next Claim</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {history.map((allocation, index) => (
              <tr key={index}>
                <th scope="row">{allocation.index}</th>
                <td>{parseInt(allocation.totalAmount)/10**9}</td>
                <td>{parseInt(allocation.claimedAmount)/10**9}</td>
                <td>{timeLeft(allocation.nextClaimTime * 1000)}</td>
                <td>{timeLeft(allocation.nextClaimTime * 1000) == 'now' && <Button onClick={() => claimAllocation(allocation.index-1)}>Claim</Button>}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

HistoryTable.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HistoryTable;
