import React from 'react';
import { Container } from 'reactstrap';

import './tokenomics.css';

import tokenomicsWrapper from '../../assets/images/tokenomics-wrapper.png';
import partnersWrapperMobile from '../../assets/images/tokenomics-wrapper-mobile.png'

export default function Tokenomics() {
  
  const textStyle = {
    color: 'blue',
    textDecoration: 'underline'
  };

  return (
    <>
      <section id="tokenomics" className="section-wrapper tokenomics">
        <Container>
          <div className="content-inner d-flex flex-column ">
            <div className="section-head d-flex flex-column align-items-center">
              <div className="tokenomics-wrapper">
                <img
                  className="wrapper-desktop"
                  src={tokenomicsWrapper}
                  alt=""
                />
                <img className='wrapper-mobile' src={partnersWrapperMobile} alt="" />
              </div>
              <h2 className="title">Tokenomics</h2>
              <h6 className="sub-title">
              Explore the token distribution Model employed by PLOUTOS to drive continuous demand and utility as well as fairness and stability. <span style={textStyle}>Learn More...</span> 
              </h6>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
