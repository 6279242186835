import React from 'react';

import './gaming.css';

import player from '../../assets/images/player.png';
import game from '../../assets/images/game.png';

const PlayerSessionTable = () => {
  return (
    <div className="table-wrapper">
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Game</th>
              <th>Player</th>
              <th>Token</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img src={game} alt="" className="table-img " />
                Chess
              </td>
              <td>
                <img src={player} alt="" className="table-img" />
                user1
              </td>
              <td>PLT</td>
              <td>
                <span className="token-value">12,2453</span>
              </td>
            </tr>
            <tr>
              <td>
                <img src={game} alt="" className="table-img " />
                Crash
              </td>
              <td>
                <img src={player} alt="" className="table-img" />
                user2
              </td>
              <td>SOL</td>
              <td>
                <span className="token-value">3,2453</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerSessionTable;
