import React from 'react';
import { Container } from 'reactstrap';

import './partners.css';

import partnersWrapper from '../../assets/images/partners-wrapper.png'
import partnersWrapperMobile from '../../assets/images/partners-wrapper-mobile.png'
import partner1 from '../../assets/images/alphamore.png';
import partner2 from '../../assets/images/cmc.png';
import partner3 from '../../assets/images/coingecko.png';
import partner4 from '../../assets/images/cyberscope.png';

const partners = [partner1, partner2, partner3, partner4];

export default function Partners() {
  return (
    <>
      <section id="partners" className="section-wrapper partners">
        <Container>
          <div className="content-inner d-flex flex-column ">
            <div className="section-head d-flex flex-column align-items-center">
              <div className="partners-wrapper">
                <img className='wrapper-desktop' src={partnersWrapper} alt="" />
                <img className='wrapper-mobile' src={partnersWrapperMobile} alt="" />
              </div>
              <h2 className="title">Our Trusted Partners</h2>
              <h6 className="sub-title">
                Trusted by to companies, explore our partners
              </h6>

            </div>
          <div className="partner-logos">
            {partners.map((partner, index) => (
              <img
                key={index}
                src={partner}
                alt={`Partner ${index + 1}`}
                className="partner-logo"
              />
            ))}
          </div>
          </div>
        </Container>
      </section>
    </>
  );
}
