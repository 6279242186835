import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import './features.css';

import dotLeft from '../../assets/images/dot-left.png';
import dotRight from '../../assets/images/dot-right.png';
// import feature1 from '../../assets/images/f-1.png';
// import feature2 from '../../assets/images/f-2.png';
import feature3 from '../../assets/images/f-3.png';
import feature4 from '../../assets/images/f-4.png';
import feature5 from '../../assets/images/f-5.png';
import feature6 from '../../assets/images/f-6.png';

export default function Features() {
  return (
    <>
      <section className="section-wrapper features">
        <Container>
          <div className="content-inner d-flex flex-column justify-content-center">
            <div className="section-head d-flex flex-column align-items-center">
              <div className="features-wrapper">
                <img className="wrapper-left-desktop" src={dotLeft} alt="" />
                <img className="wrapper-right-desktop" src={dotRight} alt="" />
              </div>
              <h2 className="title"> CORE FEATURES</h2>
              <h6 className="sub-title">
              Discover some of the powerful features that set $PLTL apart and makes it the game changer.
              </h6>
            </div>

            <Row className="mt-0">
              {/* <Col sm={12} md={6} xl={4}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature1} alt="Feature 1" />
                      <h4 className="mt-3">Proof of Stake Technology</h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                        The PLOUTOS project offers a comprehensive solution to
                        cryptocurrency challenges, focusing on utility,
                        security, and sustainability. Addressing volatility,
                        limited real-world use, wealth concentration, and
                        environmental concerns.
                      </p>
                    </div>
                  </div>
                </div>
              </Col> */}
              {/* <Col sm={12} md={6} xl={4}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature2} alt="Feature 2" />
                      <h4 className="mt-3">
                        Real-world Utility through Diverse Ecosystem Development
                      </h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                        PLOUTOS offers a multifaceted solution to crypto
                        challenges, focusing on utility, security, and
                        sustainability. Addressing volatility, limited
                        real-world use, wealth concentration, and environmental
                        concerns.
                      </p>
                    </div>
                  </div>
                </div>
              </Col> */}
              <Col sm={12} md={6} xl={3}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature3} alt="Feature 3" />
                      <h4 className="mt-3">Enhanced Security on ALL Platforms</h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                      The PLOUTOS project is based on integrity and trust. We prioritize the safety of our users. Our smart contract underwent a rigorous auditing by Industry leaders (Cyberscope.io) to identify and mitigate potential vulnerabilities, thereby ensuring maximum security for our users on the platform.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} xl={3}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature4} alt="Feature 1" />
                      <h4 className="mt-3">NFT Development and Partnerships</h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                        Collaborating with established NFT ecosystems to introduce unique digital assets, fostering creativity and ownership in the digital space. Partnering with these platforms can foster a vibrant digital art and collectible marketplace.

                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} xl={3}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature5} alt="Feature 2" />
                      <h4 className="mt-3">PLOUTOS Pay <br /> with Telegram App & Smart Wallet</h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                        This feature enables an easier user experience when it comes to seamless crypto transactions. By integrating the CoinBase smart wallet on Telegram, we have successfully brought cryptocurrency to users at their most comfortable locations i.e on their chat spaces. This integration also enhances accessibility and usability of $PLTL as well as other tokens on the BASE network.

                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} xl={3}>
                <div
                  className="feature-card mt-5 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="1s"
                >
                  <div className="inner">
                    <div className="feature-card-header">
                      <img src={feature6} alt="Feature 3" />
                      <h4 className="mt-3">Community Engagement</h4>
                    </div>
                    <div className="content mt-3">
                      <p>
                        {/* PLOUTOS prioritizes community engagement and
                        environmental sustainability through various programs
                        and partnerships, aiming to empower its community and
                        contribute to a greener future. */}
                        Our community is very important to us. This is why we prioritize community engagement and sustainability through various programs, partnerships, contributing to a greener future, and Airdrop with the aim of empowering our community and reward early adopters.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
