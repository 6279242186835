import React from 'react';
import Slider from 'react-slick';

import './gaming.css';

import game1Image from '../../assets/images/game-1.png';
import game2Image from '../../assets/images/game-2.png';
import game3Image from '../../assets/images/game-3.png';

const gameList = [
  { id: 1, image: game1Image, name: 'Chess Board Game', time: '100hr' },
  { id: 2, image: game2Image, name: 'Chess Board Game', time: '100hr' },
  { id: 3, image: game3Image, name: 'Chess Board Game', time: '100hr' },
];

function GameList() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {gameList.map((game) => (
        <div key={game.id} className="game-list">
          <div className="game-item">
            <img src={game.image} alt="Game 1" />
            <p>{game.name}</p>
            <span>{game.time}</span>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default GameList;
