import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import './gaming.css';
import chess from '../../assets/images/chess.png';
import dotLeft from '../../assets/images/dLeft.png';
import dotRight from '../../assets/images/dRight.png';

import PlayerSessionTable from './PlayerSession';
import GameList from './GameList';

export default function Gaming() {
  return (
    <>
      <section id="gaming" className="section-wrapper gaming">
        <Container>
          <div className="content-inner d-flex flex-column ">
            <div className="section-head d-flex flex-column align-items-center">
              <h2 className="title">GAMING</h2>
            </div>

            <div className="gaming-wrap">
              <div className="gaming-action">
                <h3>Premium Token E-Gaming Platform</h3>
                <div className="action-btn d-flex justify-content-between mt-4">
                  <Link className="btn btn-danger demo-btn" to="/">
                    Demo
                  </Link>
                  <Link className="btn btn-primary purchase-btn" to="/">
                    Purchase
                  </Link>
                </div>
              </div>
              <div className="gaming-chess">
                <img src={chess} alt="" />
              </div>
            </div>

            <GameList />

            <div className="player-session">
              <div className="section-head d-flex flex-column align-items-center">
                <div className="session-wrapper">
                  <img className="wrapper-left" src={dotLeft} alt="" />
                  <img className="wrapper-right" src={dotRight} alt="" />
                </div>
                <h2 className="title">Join other players in session</h2>
              </div>
            </div>
            <PlayerSessionTable />
          </div>
        </Container>
      </section>
    </>
  );
}
