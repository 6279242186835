import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Slider from 'react-slick';

import './nft.css';

import nft1 from '../../assets/images/nft1.png';
import nft2 from '../../assets/images/nft2.png';
import nft3 from '../../assets/images/nft3.png';
import nft4 from '../../assets/images/nft4.png';
import bellIcon from '../../assets/images/notification.png';
import likeIcon from '../../assets/images/like.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const nftData = [
  { id: 1, image: nft1, owner: 'Owner Name', name: 'NFT Name' },
  { id: 2, image: nft2, owner: 'Owner Name', name: 'NFT Name' },
  { id: 3, image: nft3, owner: 'Owner Name', name: 'NFT Name' },
  { id: 4, image: nft4, owner: 'Owner Name', name: 'NFT Name' },
  { id: 1, image: nft1, owner: 'Owner Name', name: 'NFT Name' },
  { id: 2, image: nft2, owner: 'Owner Name', name: 'NFT Name' },
  { id: 3, image: nft3, owner: 'Owner Name', name: 'NFT Name' },
  { id: 4, image: nft4, owner: 'Owner Name', name: 'NFT Name' },
];

function NftMarketplace() {
  const [sliderRef, setSliderRef] = useState(null);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section id="nft" className="section-wrapper nft">
      <Container>
        <div className="content-inner">
          <div className="section-head d-flex flex-column align-items-center">
            <h2 className="title">NFT MARKETPLACE</h2>
            <h6 className="sub-title">Notable Collections</h6>
          </div>
          <div className="controls mt-5">
            <button onClick={sliderRef?.slickPrev}>
              <FaArrowLeft />
            </button>
            <button onClick={sliderRef?.slickNext}>
              <FaArrowRight />
            </button>
          </div>
          <Slider ref={setSliderRef} {...settings}>
            {nftData.map((nft) => (
              <div key={nft.id}>
                <div className="nft-card">
                  <div className="nft-image d-flex justify-content-center">
                    <img src={nft.image} alt="" />
                  </div>
                  <div className="nft-detail">
                    <div className="nft-info">
                      <span className="owner">{nft.owner}</span>
                      <div className="name-container">
                        <h5 className="name">{nft.name}</h5>
                        <img
                          src={bellIcon}
                          alt="Notification Bell"
                          className="bell-icon"
                        />
                      </div>
                      <div className="price-actions d-flex flex-column">
                        {/* <div className="price">Price: $100</div> */}
                        <div className="action-buttons d-flex justify-content-between">
                          <button className="buy-button">Buy</button>
                          <div className="like-card d-flex align-items-center justify-content-center">
                            <img src={likeIcon} alt="" className="like-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </section>
  );
}

export default NftMarketplace;
