import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import axios from 'axios';
import './priceCheck.css'

export default function PriceCheck() {
  const [cryptoData, setCryptoData] = useState([]);
  const [isMobile, setIsMobile] = useState(false)

  // Integration of CoinGecko API
  useEffect(() => {
    const fetchCryptoData = async () => {
      const res = await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d&locale=en`);
      setCryptoData(res.data);
      console.log(res.data);
    }

    fetchCryptoData();
  }, [setCryptoData])

  // Helper function to apply the correct class based on the value
  const getColorClass = (value) => {
    return value >= 0 ? 'positive' : 'negative'
  }

  // Render for smaller screen widths (<= 540)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(344 <= window.innerWidth && window.innerWidth <= 540)
    }
    
    handleResize()
    window.addEventListener('resize', handleResize)
    
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="d-flex flex-column mt-4 mx-5 border rounded">
    {cryptoData ? (
      <Table bordered hover responsive>
        <thead className="thead-dark">
            <tr>
            {/* Conditionally render headers based on screen size */}
            {isMobile ? (
              <>
                <th>#</th>
                <th>Name</th>
                <th>Price</th>
                <th>1H</th>                  
              </>
              ) : (
              <>
                <th>#</th>
                <th>Name</th>
                <th>Asset</th>
                <th>Price</th>
                <th>Total Volume</th>
                <th>1H</th>
                <th>24H</th>
                <th>7D</th> 
              </>   
            )}            
          </tr>
        </thead>
        <tbody>
          {cryptoData.map((data) => (
            <tr key={data.id} className="text-center">
              {/*Implement the responsive handling*/}
              {isMobile ? (
                <>
                  <td>{data.market_cap_rank}</td>
                  <td>
                  <img className='iconSize' src={data.image} alt={data.name} /> 
                    <span>{data.symbol}</span>                
                  </td>
                  <td>
                    <span className='price'>
                    {
                      new Intl.NumberFormat("en-IN",
                        {
                          currency: 'usd', style: 'currency'
                        }).format(data.current_price)
                    }
                    </span>
                  </td>
                  <td>
                    <span className={getColorClass(data.price_change_percentage_1h_in_currency)}>
                    {Number(data.price_change_percentage_1h_in_currency.toFixed(2))}
                    </span>
                  </td>
                </>
              ) : (
                <>
                  <td>{data.market_cap_rank}</td>
                  <td>
                  <img className='iconSize' src={data.image} alt={data.name} /> 
                    <span>{data.symbol}</span>                
                  </td>
                  <td>{data.name}</td>
                  <td>
                    <span className='price'>
                    {
                      new Intl.NumberFormat("en-IN",
                        {
                          currency: 'usd', style: 'currency'
                        }).format(data.current_price)
                    }
                    </span>
                  </td>
                  <td>{data.circulating_supply}</td>
                  <td>
                    <span className={getColorClass(data.price_change_percentage_1h_in_currency)}>
                    {Number(data.price_change_percentage_1h_in_currency.toFixed(4))}
                    </span>
                  </td>
                  <td>
                    <span className={getColorClass(data.price_change_percentage_24h_in_currency)}>
                    {Number(data.price_change_percentage_24h_in_currency.toFixed(4))}
                    </span>
                  </td>
                  <td>
                    <span className={getColorClass(data.price_change_percentage_7d_in_currency)}>
                    {Number(data.price_change_percentage_7d_in_currency.toFixed(4))}
                    </span>
                  </td>
                </>  
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>Loading data...</p>
    )}
  </div>
  )
}