import React from 'react';
import { Container } from 'reactstrap';

import './distributions.css';

// import pieBg from '../../assets/images/pie-bg.png';
// import pie from '../../assets/images/pie.png';
// import pointerLeft from '../../assets/images/pointer-left.png';
// import pointerRight from '../../assets/images/pointer-right.png';
// import pieWords from '../../assets/images/pie-words.png';
// import pieBottom from '../../assets/images/pie-bottom.png';
import Features from './Features';
import blueRay from '../../assets/images/ray-right.png';

export default function Distributions() {
  return (
    <>
      <section className="section-wrapper distributions">
        <Container>
          {/* <div className="content-inner d-flex justify-content-center">
            <img className="pie-bg" src={pieBg} alt="" />
            <img className="pie" src={pie} alt="" />
            <img className="pointer-right" src={pointerLeft} alt="" />
            <img className="pointer-left" src={pointerRight} alt="" />
            <img className="pie-words" src={pieWords} alt="" />
            <img className="pie-bottom" src={pieBottom} alt="" />
          </div> */}
        </Container>
      </section>
      <img className="ray-right" src={blueRay} alt="" />

      <Features />
    </>
  );
}
