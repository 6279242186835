import { Contract } from 'ethers';
import { useEffect, useState } from 'react';
import abi from '../web3/abi';

import { PLTL } from '../web3/contracts';
import { Web3Provider } from '@ethersproject/providers';
import { parseEther } from 'ethers/lib/utils';

const useBlockchain = () => {
  const [contractWriter, setContractWriter] = useState({});
  const [contractReader, setContractReader] = useState({});

  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [provider, setProvider] = useState();
  const [active, setActive] = useState();

  useEffect(() => {
    let node =
      'https://wild-wispy-lake.matic.quiknode.pro/d1b4079c17d824d77beaf104b78d534b1eefd322/';
    if (window.ethereum) node = window.ethereum;
    const fn = async () => {
      const p = new Web3Provider(node);
      setProvider(p);

      let c = new Contract(PLTL, abi, p.getSigner());
      setContractWriter(c);
      window.contractWriter = c;
      window.parseEther = parseEther

      c = new Contract(PLTL, abi, p);
      setContractReader(c);
      window.contractReader = c;

      if (node === window.ethereum) {
        try {
          let accounts = await p.send('eth_requestAccounts', []);
          setWalletAddress(accounts[0]);

          const bal = await p.getBalance(accounts[0]);
          setBalance(bal);
          setActive(true);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fn();
  }, [setActive]);

  return {
    contractWriter,
    contractReader,
    walletAddress,
    balance,
    setWalletAddress,
    active,
    provider,
    networkVersion: window.ethereum.networkVersion,
  };
};
export default useBlockchain;
