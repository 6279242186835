import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import './about.css';
import aboutWrapper from '../../assets/images/about-wrapper.png';
import aboutWrapper1 from '../../assets/images/about-wrapper-mobile.png';
import about from '../../assets/images/about.png';
import aboutMobile from '../../assets/images/about-mobile.png';
import audit from '../../assets/images/audit.c4a0dca2.svg'
import kyc from '../../assets/images/kyc.e45992ff.svg'

export default function About() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768);
      setIsTablet(width <= 1024 && width > 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <section id="about" className="section-wrapper about">
        <Container>
          {isMobile ? (
            <div className="content-inner-1 d-flex justify-content-center">
              <div className="about-wrapper">
                <img src={aboutWrapper1} alt="" />
              </div>

              <Row className="">
                <Col md="12" className="d-flex justify-content-center">
                  <div className="about-img wow fadeInUp" data-wow-delay="1s">
                    <img src={aboutMobile} alt="about" />
                  </div>
                </Col>
                <Col md="12" className="d-flex justify-content-center">
                  <div
                    className="about-content wow fadeInUp"
                    data-wow-delay="1s"
                  >
                    <div className="section-head">
                      <h2 className="title">About Ploutos</h2>
                    </div>
                    <div className="about-text">
                      <p>
                      PLOUTOS is more than just a cryptocurrency; it is a visionary project aimed at reshaping the future of digital finance. Founded on core principles such as innovation, transparency, and utility, $PLTL seeks to address the shortcomings of some traditional cryptocurrencies and pave the way for widespread adoption and real-world application. 
                      </p>

                      <ul>
                        <li>
                        Built on the BASE network which is known for its unmatched processing speeds and scalability, the Ploutos Platform can handle high transaction volumes required for widespread adoption.
                        </li>
                      </ul>

                      <Link
                        className="about-btn"
                        to="https://ploutoslabs.gitbook.io/ploutos-white-paper"
                        target="_blank"
                      >
                        White paper
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md="8" className='d flex justify-content-center'>
                  <Link to="https://www.coinscope.co/coin/pltl" 
                      className='badge-container'
                      target='_blank'
                  >
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={audit} alt="audit badge" />
                      </span>
                    </div>
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={kyc} alt="kyc badge" />
                      </span>
                    </div>
                  </Link>
                    <span className='detail'>* Audit and KYC Verified</span>
                </Col>
              </Row>
            </div>
          ) : isTablet ? (
            <div className="content-inner-1 d-flex justify-content-center">
              <div className="about-wrapper">
                <img src={aboutWrapper} alt="" />
              </div>

              <Row className="">
                <Col md="12" className="d-flex justify-content-center">
                  <div className="about-img wow fadeInUp" data-wow-delay="1s">
                    <img src={aboutMobile} alt="about" />
                  </div>
                </Col>
                <Col md="12" className="d-flex justify-content-center">
                  <div
                    className="about-content wow fadeInUp"
                    data-wow-delay="1s"
                  >
                    <div className="section-head">
                      <h2 className="title">About Ploutos</h2>
                    </div>
                    <div className="about-text">
                      <p>
                      PLOUTOS is more than just a cryptocurrency; it is a visionary project aimed at reshaping the future of digital finance. Founded on core principles such as innovation, transparency, and utility, $PLTL seeks to address the shortcomings of some traditional cryptocurrencies and pave the way for widespread adoption and real-world application. 
                      </p>

                      <ul>
                        <li>
                        Built on the BASE network which is known for its unmatched processing speeds and scalability, the Ploutos Platform can handle high transaction volumes required for widespread adoption.
                        </li>
                      </ul>

                      <Link
                        className="about-btn"
                        to="https://ploutoslabs.gitbook.io/ploutos-white-paper"
                        target="_blank"
                      >
                        White paper
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md="10" className='d flex justify-content-center'>
                  <Link to="https://www.coinscope.co/coin/pltl" 
                      className='badge-container'
                      target='_blank'
                  >
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={audit} alt="audit badge" />
                      </span>
                    </div>
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={kyc} alt="kyc badge" />
                      </span>
                    </div>
                  </Link>
                    <span className='detail'>* Audit and KYC Verified</span>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="content-inner-1">
              <div className="about-wrapper">
                <img src={aboutWrapper} alt="" />
              </div>
              <Row className="">
                <Col lg={9} className="mt-5">
                  <div
                    className="about-content wow fadeInUp"
                    data-wow-delay="1s"
                  >
                    <div className="section-head">
                      <h2 className="title">About Ploutos</h2>
                    </div>
                    <div className="about-text">
                      <p>
                      PLOUTOS is more than just a cryptocurrency; it is a visionary project aimed at reshaping the future of digital finance. Founded on core principles such as innovation, transparency, and utility, $PLTL seeks to address the shortcomings of some traditional cryptocurrencies and pave the way for widespread adoption and real-world application. 
                      </p>

                      <ul>
                        <li>
                        Built on the BASE network which is known for its unmatched processing speeds and scalability, the Ploutos Platform can handle high transaction volumes required for widespread adoption.
                        </li>
                      </ul>

                      <Link
                        className="about-btn"
                        to="https://ploutoslabs.gitbook.io/ploutos-white-paper"
                        target="_blank"
                      >
                        White paper
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="about-img wow fadeInUp" data-wow-delay="1s">
                    <img src={about} alt="about" />
                  </div>
                </Col>
                <Col md="12" className='d flex justify-content-center'>
                  <Link to="https://www.coinscope.co/coin/pltl" 
                    className='badge-container'
                    target='_blank'
                  >
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={audit} alt="audit badge" />
                      </span>
                    </div>
                    <div className='verification-badges'>
                      <span>
                        <img className='iconSize' src={kyc} alt="kyc badge" />
                      </span>
                    </div>
                  </Link>
                    <span className='detail'>* Audit and KYC Verified</span>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </section>
    </>
  );
}
