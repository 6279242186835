import React from 'react';
import Hero from '../components/landing/Hero';
import Partners from '../components/landing/Partners';
import Tokenomics from '../components/landing/Tokenomics';
import Distributions from '../components/landing/Distributions';
import Gaming from '../components/landing/Gaming';
import NftMarketplace from '../components/landing/Nft';
import About from '../components/landing/About';
import PriceCheck from '../components/landing/PriceCheck';

export default function Home() {
  return (
    <>
      <Hero />
      <About />
      <Partners />
      <Tokenomics />
      <Distributions />
      <Gaming />
      <PriceCheck />
      <NftMarketplace />
    </>
  );
}
