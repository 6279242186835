import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import HistoryTable from './HistoryTable';
import useBlockchain from '../../hooks/useBlockchain';
import { parseEther } from 'ethers/lib/utils';

const hist = [
  { date: '2024-03-10', amount: '500 PLT' },
  { date: '2024-03-11', amount: '750 PLT' },
];

function MainDashboard() {
  const [amount, setAmount] = useState('');
  const { contractWriter, active } = useBlockchain();

  const buyPresale = async () => {
    if (!active) {
      alert('please use a dapp enabled browser');
      return;
    }

    if (isNaN(amount)) {
      alert('Please enter ETH amount in figure');
      return;
    }

    try {
      const transactionResponse = await contractWriter.buyPresale({
        value: parseEther(amount),
      });
      await transactionResponse.wait();
  
      alert('Transaction submitted');
      location.reload();
    } catch (error) {
      console.log(error)
      alert('Transaction failed. Please confirm your ETH balance and try again')
    }
  };

  return (
    <>
      <div className="main-card">
        <Row>
          <Col md="6" sm="12" className="mt-4">
            <FormGroup>
              <Label for="allocationAmount">Amount</Label>
              <Input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="text"
                placeholder="ETH amount"
                id="allocationAmount"
                required
              />
            </FormGroup>
            <Button onClick={buyPresale} color="primary">
              Buy Presale
            </Button>
          </Col>
        </Row>

        <Row>
          <div className="history">
            <HistoryTable history={hist} />
          </div>
        </Row>
      </div>
    </>
  );
}

export default MainDashboard;
