import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import './Hero.css';
import blueRay from '../../assets/images/blue-ray.png';
import hero1 from '../../assets/images/1.png';
import hero2 from '../../assets/images/2.png';
import hero4 from '../../assets/images/4.png';
import hero6 from '../../assets/images/6.png';
import hero7 from '../../assets/images/7.png';
import hero8 from '../../assets/images/8.png';
import { BsRobot } from "react-icons/bs";

export default function Hero() {
  return (
    <>
      <img className="landing-ray" src={blueRay} alt="" />
      <section id="home" className="landing">
        <div className="hero">
          <div className="hero-main">
            <div className="hero-background "></div>
            <div className="hero-content d-flex justify-content-center align-items-center">
              <Container>
                <div className="coins">
                  <img className="img-1 hero-img" src={hero1} alt="" />
                  <img className="img-2 hero-img" src={hero2} alt="" />
                  <img className="img-3 hero-img" src={hero1} alt="" />
                  <img className="img-4 hero-img" src={hero4} alt="" />
                  <img className="img-5 hero-img" src={hero1} alt="" />
                  <img className="img-6 hero-img" src={hero6} alt="" />
                  <img className="img-7 hero-img" src={hero7} alt="" />
                  <img className="img-8 hero-img" src={hero8} alt="" />
                </div>
                <div className="content-title">
                  <h1
                    className="wow fadeIn"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <span>Ploutos</span> <br /> Redefining Cryptocurrency.
                  </h1>
                  <p
                    className="wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    With a limited supply of tokens released gradually over eight (8) years, $PLTL is set to deliver a stable, secure and usable Cryptocurrency. Join us as we change the narrative and set the stage for a stable and rewarding crypto experience!
                  </p>
                  <div className="hero-actions action-btn d-flex mt-4">
                    <Link
                      to="https://p2pb2b.com/token-sale/PLTL-743/"
                      className="btn btn-primary hero-btn" 
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Buy $PLTL
                    </Link>
                    <Link
                      to="https://t.me/ploutos_labs_bot/app?startapp=581950280"
                      className="btn btn-primary hero-btn ml-3"
                      style={{ backgroundColor: '#B8860B' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bot&nbsp;<BsRobot />
                    </Link>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
